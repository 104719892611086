.about__intro {
    color: #fff;
    padding: 1rem;
    position: relative;
    &:before{
        position: absolute;
        background-color: $brand;
        // background-image: url('/images/doctor-live-operation.jpg');
        background-size: cover;

        top: 0;
        right: 0;
        left: 0;
        bottom: 35%;
        content: "";
        z-index: -1;
    }
    .dots{
        position: absolute;
        bottom: 44%;
        left: -1rem;
        width: 4rem;
        height: 4rem;
        @include mq($from:lg-plus){
          left: 6vw;
          width: 10rem;
          height: 10rem;
        }
    }
    .arrow{
      position: absolute;
      bottom: 60%;
      right: -1rem;
      width: 4rem;
      height: 4rem;
      @include mq($from:lg-plus){
        right: 1vw;
        width: 10rem;
        height: 10rem;
      }
    }
  }

  .dots{
      width: 10rem;
      height: 10rem;
      opacity: .4;
  }

  .arrow{
      width: 10rem;
      height: auto;
  }

  .about__intro__wrapper {
      @include mq($from:md-plus){
          padding: 0 2rem;
          margin: 0 auto;
      }
      @include mq($from:lg-plus){
          padding: 0;
          width: 75vw;
      }
  }

  .about__intro__title {
    margin-top: 0;
    @include mq($from:md-plus){
        margin-top: 1em;
    }
    @include mq($from:lg-plus){
        margin: 1em 0 2rem;
        font-size: 5em;
    }
  }

  .about__intro__paragraph {
      @include mq($from:md-plus){
          margin-bottom: 3em;
      }
      @include mq($from:lg-plus){
          margin-bottom: 6em;
      }
  }

  .about__intro__grid {
      margin: 0 auto;

      display: grid;
      grid-template-columns: 3fr 2fr;
      grid-gap: 2rem;
      @include mq($from:md-plus){
         padding: 0 2rem;
      }
      @include mq($from:lg-plus){
          padding: 0;
          max-width: 75vw;
      }
      img{
          width: 100%;
          height: auto;
      }
  }

  .about__mission {
    padding: 1rem;
    @include mq($from: md-plus) {
      padding: 0;
      height: 50vh;
      min-height: max-content;
      display: flex;
      align-content: center;
      flex-direction: column;
      justify-content: center;
    }
  }

  .mission__title {
    color: #f9ab00;
    @include mq($from: md-plus) {
      text-align: center;
      margin-bottom: 2em;
    }
  }

  .mission__paragraph {
    @include mq($from: md-plus) {
      margin: 0 auto;
      max-width: 40rem;
      text-align: center;
    }
    @include mq($from: lg-plus) {
      font-size: 1.4em;
      max-width: 75vw;
    }
    @include mq($from: xl) {
      font-size: 1.6em;
    }
  }

  .about__who-we-are {
    padding: 1rem;
    max-width: 40rem;
    position: relative;
    @include mq($from: lg) {
      padding: 0;
      max-width: 70vw;
      margin-left: auto;
      padding-right: 12.5vw;
      height: 40vh;
      min-height: max-content;
      display: flex;
      align-content: center;
      flex-direction: column;
      justify-content: center;
    }
    .dots{
        display: none;
        @include mq($from:lg){
            display: block;
            position: absolute;
            left: -18vw;
            opacity: .2;
            height: 10vw;
            width: 10vw;
        }
    }
  }

  .who-we-are__title {
    @include mq($from: lg) {
      margin-bottom: 1em;
    }
  }

  .who-we-are__paragraph {
  }

  .about__our-goal {
    padding: 1rem;
    @include mq($from: lg) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 8rem;

    }
  }

  .our-goal__message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include mq($from:lg-plus){
          padding-right: 12.5vw;
      }
  }

  .our-goal__title {
      margin-bottom: 1em;
  }

  .our-goal__paragraph {
  }