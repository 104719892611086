.groups{
    @include reset-list;
}

.group{
    margin-bottom: 2rem;
}
.group__users{
    @include reset-list;
    display: flex;
    margin-top:1rem;
    flex-flow: row wrap;
}

.group__user{
    display: flex;
    align-items: center;
    border: 1px solid #eee;
    padding: .5rem;
    border-radius: 10em;
    margin-right: 1rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    position: relative;
    > *{
        margin-right: .5rem;
    }
    .close{
        position: relative;
        top: -3px;
        left: 3px;
    }
}

.group__header{
    a{
        margin-left: 2rem;
    }
}