.product-page {
	.section-6 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100vh;
		max-height: 40rem;
		min-height: auto;
		padding: 4rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		background-image: -webkit-gradient(linear, left top, left bottom, color-stop(32%, transparent), color-stop(87%, rgba(0, 0, 0, 0.63))), url("https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddc9ab84b38b7dc10657a0c_product-bg.jpg");
		background-image: linear-gradient(180deg, transparent 32%, rgba(0, 0, 0, 0.63) 87%), url("https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddc9ab84b38b7dc10657a0c_product-bg.jpg");
		background-position: 0px 0px, 50% 50%;
		background-size: auto, cover;
	}

	.heading-6 {
		max-width: 18em;
		margin-bottom: 1.5rem;
		color: #fff;
		font-size: 3rem;
	}

	.paragraph-4 {
		max-width: 37rem;
		color: #fff;
	}

	.div-block-15 {
		display: -ms-grid;
		display: grid;
		padding: 0rem;
		grid-auto-columns: 1fr;
		grid-column-gap: 3rem;
		grid-row-gap: 16px;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: auto;
		grid-template-rows: auto;
	}

	.div-block-15.block-15-2 {
		grid-column-gap: 3rem;
	}

	.heading-7 {
		margin-top: 2rem;
		margin-bottom: 2rem;
		color: #0ca3d9;
		font-size: 1.5rem;
	}

	.heading-8 {
		margin-top: 0rem;
		font-size: 2rem;
		font-weight: 500;
	}

	.paragraph-5 {
		margin-top: 2rem;
	}

	.section-7 {
		display: block;
		padding: 4rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.section-7.section-7-2 {
		background-color: #efefef;
	}

	.paragraph-6 {
		max-width: 35em;
		margin-bottom: 2rem;
	}

	.paragraph-7 {
		margin-bottom: 2rem;
	}

	.section-8 {
		padding: 0rem;
	}

	.image-6 {
		width: 100%;
		background-image: url("https://d3e54v103j8qbb.cloudfront.net/img/example-bg.png");
		background-position: 0px 0px;
		background-size: 125px;
	}

	.section-9 {
		padding: 4rem;
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.8)), to(transparent)), url("https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddc9a07ab100873c20a941c_operating-room2.97db3107.jpg");
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8), transparent), url("https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddc9a07ab100873c20a941c_operating-room2.97db3107.jpg");
		background-position: 0px 0px, 0px 0px;
		background-size: auto, cover;
	}

	.heading-9 {
		margin-top: 0px;
		margin-bottom: 2rem;
		color: #fff;
		text-align: center;
	}

	.paragraph-8 {
		margin-bottom: 3rem;
		color: #fff;
		text-align: center;
	}

	.div-block-16 {
		display: -ms-grid;
		display: grid;
		grid-auto-columns: 1fr;
		grid-column-gap: 4rem;
		grid-row-gap: 16px;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: auto;
		grid-template-rows: auto;
	}

	.heading-10 {
		font-size: 1rem;
	}

	.div-block-17 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 3rem;
		padding-right: 3rem;
		padding-bottom: 3rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.paragraph-9 {
		margin-bottom: 0rem;
	}

	.image-7 {
		width: 24rem;
		padding-left: 3rem;
	}

	.div-block-18 {
		overflow: visible;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.div-block-19 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.div-block-20 {
		position: static;
	}

	.product-join {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		min-height: 50vh;
		padding: 4rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-image: -webkit-gradient(linear, left top, left bottom, from(#000), to(rgba(0, 0, 0, 0.45))), url("https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddc9f6672abe378773f1c87_product-join-cta.jpg");
		background-image: linear-gradient(180deg, #000, rgba(0, 0, 0, 0.45)), url("https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddc9f6672abe378773f1c87_product-join-cta.jpg");
		background-position: 0px 0px, 50% 50%;
		background-size: auto, cover;
	}

	.div-block-21 {
		text-align: center;
	}
}