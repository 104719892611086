.surgeries__posts{
    @include reset-list;
}

.surgeries__post{
    display: flex;
    align-items: baseline;
    &:not(:last-child){
        margin-bottom: 1.5rem;
    }
    .surgeries__post__title{
        margin: 0;
        flex: 1 1 auto;
    }
    h3{
        font-size: 1rem;
        @include mq($from:lg-plus){
            font-size: 1.6rem;
        }
    }
    *:not(:last-child){
        margin-right: 2rem;

    }
}