.edit__surgery{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    @include mq($from:md){
        grid-template-columns: 2fr 1fr;
    }
    @include mq($from:lg){
        grid-template-columns: 1fr;
    }
    @include mq($from:lg-plus){
        grid-template-columns: 2fr 1fr;
    }
}

.edit__alpha{
    & > *:not(:last-child){
        margin-bottom: 1rem;
    }
}

.edit__beta{
    & > *:not(:last-child){
        margin-bottom: 2rem;
    }
}

.edit__img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    
    .edit__img-uploader{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .input--file{
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
        
    }
    .input--file + label {
        font-size: 1em;
        font-weight: 200;
        padding: $button-padding;
        color: white;
        background-color: $brand;
        display: inline-block;
        cursor: pointer;
        svg{
            position: relative;
            top: -2px;
        }
    }
    
    .input--file:focus + label,
    .input--file + label:hover {
        background-color: lighten($brand, 20%);
    }
    .input--file:focus + label {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
    }

}

.edit__surgery input{
    width: 100%;
    padding: .5em 1em;
    border: 1px solid #eee;
}
.edit__description-textarea{
    width: 100%;
    min-height: 24em;
    padding: 1em;
    border: 1px solid #eee;
}

.edit__url{
    .edit__zoom-url{
        margin-bottom: 1rem;
    }
    @include mq($from:xl){
        display: flex;
        width: 100%;
        .edit__zoom-url{
            margin-bottom: 0;
        }
        > *{
            width: 50%;
            &:first-child{
                margin-right: 1rem;
            }
        }
    }
}

.edit-surgery__form{
    display: flex;
    label:not(:last-child){
        padding-right: 2rem;
    }
    [for="form--duration"]{
        span{
            display: block;
        }
    }
}