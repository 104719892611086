.schedule-surgery__form{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-flow: dense;
    grid-gap: .5rem;
    @media screen and (min-height: 700px){
        grid-gap: 2rem;
    }
    label{
        span{
            margin-bottom: .5rem;
            display: block;
        }
    }
    
    textarea{
        min-height: initial;
        height: calc(100% - 2rem);
    }
    
    label[for=form--topic]{
        grid-column: 1 / span 4;
        input{
            display: block;
            width: 100%;
        }
    }
    label[for=form--description]{
        grid-column: span 4;
        grid-row: span 3;
    }
    label[for=form--duration]{
        grid-column: span 2;
        select{
            margin-right: .5rem;
        }
        select:not(:first-of-type){
            margin: 0 .5rem;
        }
        span{
            display: block;
        }
    }
    label[for=form--time]{
        grid-column: span 2;
        span{
            display: block;
        }
        #form--time{
            display: block;
            width: 100%;
        }
    }
    label[for=form--date]{
        grid-column: span 2;
    }
    label[for=hostVideo]{
        grid-column: span 2;
        input{
            margin-right: .5rem;
        }
        input:not(:first-of-type){
            margin: 0 .5rem;
        }
        span{
            margin-right: 1em;
        }
    }
    label[name=participantVideo]{
        grid-column: span 2;
        input{
            margin-right: .5rem;
        }
        input:not(:first-of-type){
            margin: 0 .5rem;
        }
        span{
            margin-right: 1em;
        }
    }
    label[for=record]{
        grid-column: span 2;
        span{
            margin-right: 1em;
            display: inline;
        }
    }
    #form--date{
        width: 100%;
    }
}

.schedule-surgery__form__button{
    //grid-column: 1;
    grid-column: span 2;
}