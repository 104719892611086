.users{
    list-style-type: none;
    margin: 0; padding: 0;
}

.user{
    @include mq($until:sm-plus){
        font-size: .8em;
        grid-column-gap: .5rem;
    }
    display: grid;
    grid-template-columns: 3rem minmax(min-content, 1fr) min-content;
    grid-column-gap: 1rem;
    margin-bottom: 1rem;
   @include mq($from:lg-plus){
    grid-template-columns: 4rem 1fr 1fr 10rem;
    align-items: center; 
    grid-column-gap: 1rem;
    margin-bottom: 1rem;
   }
}

.user__name:not(.group__user .user__name){
    font-weight: bolder;
    text-transform: capitalize;
}

.user__img{
    
    @include mq($until:lg-plus){
        grid-row: 1 / span 2;
    }
    @include mq($from:lg-plus){
        margin-right: 1rem;
    }
}

.user__email{
    @include mq($until:lg-plus){
        grid-column: 2;
    }   
}

.users__table-header{
    border-bottom: 1px solid #eee;
    padding-bottom: 1.5rem;
    margin-bottom: 2rem;
    @include mq($until:lg-plus){
        display: none;
    } 
}

.user__groups{
    @include mq($until:sm-plus){
        grid-row: 3;
        margin-top: 1rem;
    }
    select{
        @include mq($until:lg-plus){
            min-width: 10rem;
        }
        width: 100%;
    }
}